import { useRouter } from 'next/router'
import { useEffect, useRef, useState } from 'react'
import {
  BigScreen,
  SmallScreen
} from '@smu-chile/pkg-unimarc-components/helpers'
import { IUsualsAndFavoritesData } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IGetMyUsualsAndFavorites'
import {
  Cookies,
  datalayerSimpleEvent,
  getCurrentDomain,
  isValidArrayWithData,
  patchUtmSource,
  sleep,
  trigger,
  useEvents,
  useNewCartUnimarc,
  useQueryClient
} from '@smu-chile/pkg-unimarc-hooks'
import { ModalDesktop } from './ModalDesktop'
import { ModalMobile } from './ModalMobile'
import { Data } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IOrderForm'
import { ICart } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/INewCartUnimarc'

interface MyUsualsAndFavoritesWrapperProps {
  favorites: IUsualsAndFavoritesData
  isLoadingOrderForm: boolean
  myUsuals: IUsualsAndFavoritesData
  orderformData: Data
  handleClose: () => void
}

export const MyUsualsAndFavoritesWrapper = ({
  favorites,
  isLoadingOrderForm,
  myUsuals,
  orderformData,
  handleClose
}: MyUsualsAndFavoritesWrapperProps): React.ReactElement => {
  const router = useRouter()
  const domain = getCurrentDomain()
  const queryClient = useQueryClient()
  const wasLoadingOrderForm = useRef(false)
  const { refetch } = useNewCartUnimarc()
  const contentRef = useRef(null)

  // failed data to show noStockToast
  const [failedData, setFailedData] = useState<ICart>(null)

  const handleCloseModal = () => {
    trigger({ eventType: 'myUsuals', data: { show: false } })
    handleClose()
  }

  const utmSourceValidate = async (data: Data) => {
    if (
      !data?.marketingData?.utmSource ||
      !data?.marketingData ||
      data?.marketingData?.utmSource === 'unimarc-app'
    ) {
      await patchUtmSource('unimarc-web')
    }
  }

  const handleClickButton = () => {
    utmSourceValidate(orderformData)
    datalayerSimpleEvent({
      event: 'interacciones carro de compras',
      eventAction: 'clic',
      eventCategory: 'modal no te olvides',
      eventLabel: 'boton ir a pagar'
    })
    Cookies.remove('eventSent', {
      domain,
      path: '/'
    })
    router.push('/MyShipments')
  }

  const scrollToTopOnContent = () => {
    if (contentRef?.current) {
      contentRef.current.scrollTop = 0
    }
  }

  const handleCloseNoStockToast = () => {
    setFailedData(null)
    Cookies.set('addToCart', '[]', {
      domain,
      path: '/'
    })
  }

  useEffect(() => {
    datalayerSimpleEvent({
      event: 'interacciones carro de compras',
      eventAction: 'view',
      eventCategory: 'modal no te olvides',
      eventLabel: 'carga modal no te olvides'
    })
  }, [])

  useEffect(() => {
    if (!isLoadingOrderForm && wasLoadingOrderForm.current) {
      refetch()
    }

    wasLoadingOrderForm.current = isLoadingOrderForm
  }, [isLoadingOrderForm])

  // Event to validate if the orderform generate error with some item
  // and show the NoStockToast
  useEvents({
    eventType: 'loadingOrderForm',
    callBack: async ({ detail: { loading, newResponse } }) => {
      if (!loading && !newResponse) {
        await sleep(1000)
        const getMutateData = queryClient
          .getMutationCache()
          .findAll({ mutationKey: 'mutateOrderForm' })
          .slice(-1)[0]

        const dataCartMutate = getMutateData?.state?.data as ICart

        if (isValidArrayWithData(dataCartMutate?.removedProducts)) {
          setFailedData(dataCartMutate)
        }
      }
    }
  })

  return (
    <>
      <BigScreen>
        <ModalDesktop
          contentRef={contentRef}
          failedData={failedData}
          favorites={favorites}
          handleClick={handleClickButton}
          handleClose={handleCloseModal}
          handleCloseNoStockToast={handleCloseNoStockToast}
          isLoadingOrderForm={isLoadingOrderForm}
          myUsuals={myUsuals}
          scrollToTopOnContent={scrollToTopOnContent}
        />
      </BigScreen>
      <SmallScreen>
        <ModalMobile
          contentRef={contentRef}
          failedData={failedData}
          favorites={favorites}
          handleClick={handleClickButton}
          handleClose={handleCloseModal}
          handleCloseNoStockToast={handleCloseNoStockToast}
          isLoadingOrderForm={isLoadingOrderForm}
          myUsuals={myUsuals}
          scrollToTopOnContent={scrollToTopOnContent}
        />
      </SmallScreen>
    </>
  )
}
