import { useEffect, useState } from 'react'
import {
  Column,
  Container,
  Row,
  Spacer
} from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  SmallScreen,
  getBemId,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'
import {
  Cookies,
  currentOrderformInfoTag,
  datalayerSimpleEvent,
  getCurrentDomain,
  isValidArrayWithData,
  sleep,
  updateTagOrderForm,
  useMobile,
  useMyShipments,
  useNewCartUnimarc,
  useOrderForm,
  useSession,
  useShipmentWindow
} from '@smu-chile/pkg-unimarc-hooks'
import {
  IAvailableWindows,
  IShipmentsData
} from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IMyShipments'
import { AddressSelected } from 'components/AddressSelected'
import { MyShipmentsTitle } from './MyShipmentsTitle'
import { DateOptions } from './DateOptions'
import { TimeOptions } from './TimeOptions'
import { getTimesForSelectedDay } from './helpers/getTimesForSelectedDay'
import { formatDeliveryText } from './helpers/formatDeliveryText'
import ButtonGoToPay from './ButtonGoToPay'
import { MyShipmentsLoader } from './MyShipmentsLoader'
import { ShipmentError } from './ShipmentError'
import { formatDateToSendOnTag } from './ButtonGoToPay/helpers/formatDateToSendOnTag'
import { MembershipToast } from 'components/Body/ProductsTotalizers/TooltipWrapper/MembershipToast'
import { matchWindowsToSla } from './helpers/matchWindowsToSla'
import styles from './MyShipments.module.css'

interface MyShipmentsComponentProps {
  objetSrcImagesIconAddress: {
    imgByke: string
    imgStore: string
  }
  shipmentErrorImage: string
  showIndexingMembership: boolean
}

export const MyShipmentsComponent = ({
  objetSrcImagesIconAddress,
  shipmentErrorImage,
  showIndexingMembership
}: MyShipmentsComponentProps): React.ReactElement => {
  const domain = getCurrentDomain()
  const { isMobile } = useMobile()
  const { data: dataCart, isLoading: isLoadingCart } = useNewCartUnimarc()
  const { data, isLoading, isFetching } = useMyShipments()
  const { handleShipmentWindow, loading } = useShipmentWindow()
  const { isLoggedIn } = useSession()
  const [deliveryInfo, setDeliveryInfo] = useState({
    day: '',
    hour: ''
  })
  const [daySelected, setDaySelected] = useState<string>('')
  const [availableTimes, setAvailableTimes] = useState<IAvailableWindows[]>([])
  const [scheduleSelected, setScheduleSelected] = useState<IAvailableWindows>()
  const [firstDayAvailable, setFirstDayAvailable] =
    useState<IAvailableWindows>()
  const [loadingWindow, setLoadingWindow] = useState(false)
  const [sendEventOnLoad, setSendEventOnLoad] = useState(false)
  const [allDeliveryWindows, setAllDeliveryWindows] = useState<
    IAvailableWindows[]
  >([])

  const { data: orderForm } = useOrderForm()
  const orderFormData = orderForm?.data

  const shipmentData: IShipmentsData = data

  const typeOfShipment =
    shipmentData?.selectedAddress?.addressType === 'pickup-in-point'
      ? 'Retiras'
      : 'Recibes'

  const handleAvailableTimes = (startDate: string) => {
    const scheduleAvailable = getTimesForSelectedDay(
      allDeliveryWindows,
      startDate
    )
    setAvailableTimes(scheduleAvailable)
    setDaySelected(startDate?.split('T')[0])
  }

  const handleSchedule = async (date: IAvailableWindows, firstTime = false) => {
    const { dayFormated, hoursFormated } = formatDeliveryText(
      date?.startDateUtc,
      date?.endDateUtc
    )
    setDeliveryInfo({
      day: dayFormated,
      hour: hoursFormated
    })
    setScheduleSelected(date)
    if (!firstTime) {
      handleSendEvent()
      await handleShipmentWindow(date)
    }
  }

  const handleRedirect = async () => {
    Cookies.remove('shipmentData', {
      domain,
      path: '/'
    })
    await sleep(3000)
    window.location.href = process.env.NEXT_PUBLIC_URL_CHECKOUT
  }

  const handleSendEvent = () => {
    currentOrderformInfoTag({
      event: 'add_shipping_info',
      shipping_date: formatDateToSendOnTag(scheduleSelected?.startDateUtc),
      'shipping-earliest-date': formatDateToSendOnTag(
        firstDayAvailable?.startDateUtc
      ),
      shipping_tier: data?.selectedAddress?.addressType,
      site: 'Unimarc'
    })
  }

  useEffect(() => {
    setLoadingWindow(loading)
  }, [loading])

  useEffect(() => {
    if (
      !isLoading &&
      isValidArrayWithData(allDeliveryWindows) &&
      shipmentData?.selectedWindow?.startDateUtc &&
      shipmentData?.selectedWindow?.endDateUtc
    ) {
      const { startDateUtc, endDateUtc } = shipmentData.selectedWindow
      const { dayFormated, hoursFormated } = formatDeliveryText(
        startDateUtc,
        endDateUtc
      )
      setDeliveryInfo({
        day: dayFormated,
        hour: hoursFormated
      })
      handleAvailableTimes(shipmentData?.selectedWindow?.startDateUtc)
      handleSchedule(shipmentData?.selectedWindow, true)
      setFirstDayAvailable(allDeliveryWindows?.[0])
    }
  }, [data, isLoading, allDeliveryWindows])

  useEffect(() => {
    if (shipmentData?.selectedAddress?.addressType) {
      datalayerSimpleEvent({
        event: 'page_view',
        tipo_pagina: 'Mi envio',
        shipping_tier: shipmentData?.selectedAddress?.addressType
      })
    }
  }, [shipmentData])

  useEffect(() => {
    if (scheduleSelected && firstDayAvailable) {
      Cookies.set(
        'shipmentData',
        JSON.stringify({
          scheduleSelected,
          firstDayAvailable
        }),
        {
          domain,
          path: '/'
        }
      )
    }
  }, [scheduleSelected?.startDateUtc, firstDayAvailable?.startDateUtc])

  useEffect(() => {
    if (
      !isLoading &&
      data &&
      scheduleSelected &&
      orderFormData &&
      !sendEventOnLoad
    ) {
      setSendEventOnLoad(true)
      updateTagOrderForm({ orderForm: orderFormData || { noSession: true } })
      handleSendEvent()
    }
  }, [isLoading, data, scheduleSelected, orderFormData])

  useEffect(() => {
    if (!isLoading && !isFetching) {
      if (
        isValidArrayWithData(data?.availableSlas) &&
        isValidArrayWithData(data?.availableWindows)
      ) {
        const allWindows = matchWindowsToSla(
          shipmentData?.availableWindows,
          shipmentData?.availableSlas
        )
        setAllDeliveryWindows(allWindows)
      } else if (isValidArrayWithData(data?.availableWindows)) {
        setAllDeliveryWindows(data?.availableWindows)
      }
    }
  }, [isLoading, data, isFetching])

  if (shipmentData?.error)
    return (
      <ShipmentError
        errorCode={shipmentData?.code ?? ''}
        shipmentErrorImage={shipmentErrorImage}
      />
    )

  if (
    !shipmentData?.error &&
    (isLoading ||
      !isLoggedIn ||
      isFetching ||
      isLoadingCart ||
      !isValidArrayWithData(allDeliveryWindows))
  )
    return <MyShipmentsLoader />

  return (
    <Container
      customHeight='100%'
      justifyContent='center'
    >
      <BigScreen>
        <Container
          isWrap
          maxWidth={getGlobalStyle('--width-max-desktop')}
          position='relative'
        >
          <Row>
            <Column
              id={getBemId('myShipments', 'container')}
              isWrap
              margin='0px 56px 0px 0px'
              maxWidth='690px'
              minWidth='690px'
              position='relative'
            >
              {loadingWindow && (
                <Container
                  customClassName={styles.loadingOverlay}
                  position='absolute'
                />
              )}
              <AddressSelected
                fromMyShipment={true}
                isMobile={isMobile}
                objetSrcImagesIconAddress={objetSrcImagesIconAddress}
                selectedAddress={dataCart?.address}
              />
              <Spacer.Horizontal customSize={30} />
              <MyShipmentsTitle
                deliveryInfo={deliveryInfo}
                price={scheduleSelected?.price}
                typeOfShipment={typeOfShipment}
              />
              <Spacer.Horizontal customSize={30} />
              {isValidArrayWithData(allDeliveryWindows) && (
                <DateOptions
                  availableWindows={allDeliveryWindows}
                  daySelected={daySelected}
                  handleAvailableTimes={handleAvailableTimes}
                />
              )}
              <Spacer.Horizontal size={24} />
              {daySelected && (
                <TimeOptions
                  availableTimes={availableTimes}
                  handleSchedule={handleSchedule}
                  scheduleSelected={scheduleSelected}
                />
              )}
              <Spacer.Horizontal size={20} />
            </Column>
            <Column position='sticky'>
              {showIndexingMembership && (
                <Column
                  justifyContent='end'
                  margin='0 0 10px 0'
                  maxWidth='max-content'
                >
                  <MembershipToast
                    customWidth='414px'
                    showIndexingMembership={showIndexingMembership}
                  />
                </Column>
              )}
              <Row
                border={`0.3px solid ${getGlobalStyle(
                  '--color-neutral-gray-divider'
                )}`}
                borderRadius={getGlobalStyle('--border-radius-md')}
                id={getBemId('shipmentButton', 'container')}
                padding='24px'
              >
                <ButtonGoToPay
                  isLoading={loading}
                  onClick={handleRedirect}
                />
              </Row>
            </Column>
          </Row>
        </Container>
      </BigScreen>
      <SmallScreen>
        <Container
          customHeight='100%'
          isWrap
        >
          <Column
            customClassName={styles.minHeightMobile}
            customHeight='100%'
            isWrap
            maxWidth={getGlobalStyle('--width-max-mobile')}
            position='relative'
          >
            {loadingWindow && (
              <Container
                customClassName={styles.loadingOverlay}
                position='absolute'
              />
            )}
            <Spacer.Horizontal customSize={76} />
            <Row padding='0 16px'>
              <AddressSelected
                fromMyShipment={true}
                isMobile={isMobile}
                objetSrcImagesIconAddress={objetSrcImagesIconAddress}
                selectedAddress={shipmentData?.selectedAddress}
              />
            </Row>
            <Spacer.Horizontal customSize={25} />
            <Row padding='0 16px'>
              <MyShipmentsTitle
                deliveryInfo={deliveryInfo}
                isMobile={isMobile}
                price={scheduleSelected?.price}
                typeOfShipment={typeOfShipment}
              />
            </Row>
            <Spacer.Horizontal customSize={25} />
            <Row padding='0 0 0 16px'>
              {isValidArrayWithData(allDeliveryWindows) && (
                <DateOptions
                  availableWindows={allDeliveryWindows}
                  daySelected={daySelected}
                  handleAvailableTimes={handleAvailableTimes}
                  isMobile={isMobile}
                />
              )}
            </Row>
            <Spacer.Horizontal size={20} />
            {daySelected && (
              <Row padding='0 0 0 16px'>
                <TimeOptions
                  availableTimes={availableTimes}
                  handleSchedule={handleSchedule}
                  isMobile={isMobile}
                  scheduleSelected={scheduleSelected}
                />
              </Row>
            )}
            <Spacer.Horizontal size={20} />
          </Column>
          <Container
            absoluteDefault='bottomLeft'
            backgroundColor={getGlobalStyle('--color-base-white')}
            id={getBemId('totalizers', 'container')}
            isWrap
            padding='24px'
            position='fixed'
            zIndex={getGlobalStyle('--z-index-10')}
          >
            <Container
              absoluteDefault='bottomLeft'
              backgroundColor={getGlobalStyle('--color-base-white')}
              borderRadius='16px 16px 0 0'
              boxShadow={getGlobalStyle('--box-shadow-xs')}
              isWrap
              minHeight='76px'
              padding='24px'
              position='fixed'
            >
              {showIndexingMembership && (
                <MembershipToast
                  showIndexingMembership={showIndexingMembership}
                />
              )}
              <Container
                absoluteDefault='bottomLeft'
                backgroundColor={getGlobalStyle('--color-base-white')}
                borderRadius='12px 12px 0 0'
                boxShadow='4px -6px 18px 0px rgba(0, 0, 0, 0.05)'
                isWrap
                minHeight='76px'
                padding='20px 16px'
                position='fixed'
              >
                <ButtonGoToPay
                  isLoading={loading}
                  onClick={handleRedirect}
                />
              </Container>
            </Container>
          </Container>
        </Container>
      </SmallScreen>
    </Container>
  )
}
